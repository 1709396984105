@import '~antd/dist/antd.less';

@navbar-height:  90px;

.ant-layout-header {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: @navbar-height!important;
	//background: #fff!important;
	//border-bottom   : 1px solid #eee;
	//box-shadow      : 0 2px 8px #f0f1f2;
	padding: 0 48px;
}

.ant-drawer-header {
	padding: 20px 24px;
}

.ant-drawer-body {
	padding: 0;

	.ant-menu-horizontal {
		border-bottom: none;

		.ant-menu-item,
		.ant-menu-submenu {
			display: inline-block;
			width  : 100%;
		}

		.ant-menu-item:hover {
			border-bottom-color: transparent;
		}
	}
}

.navbar {
	display        : flex;
	height         : 100%;
	align-items    : center;
	justify-content: space-between;

	.navbar-logo {
        height: @navbar-height;
        line-height: @navbar-height;

		img{
			height: 100%;
			width: 50vw;
			width: min(50vw, 256px);

			display: flex;
			align-items: center; /* align vertical */

		}
		/*a {
			font-size: 20px;
		}*/
	}

	.navbar-toggler {
		display: none;
	}

	.navbar-menu {
		height: @navbar-height;
        line-height: @navbar-height;
		flex-grow: 1;

		.ant-menu-horizontal {
			border-bottom: none;
			justify-content: flex-end !important;
		}

		.ant-menu {
			height: 100%;
		}

		.ant-menu-item {
			// top: -1px;

			a {
				display      : block;
				margin-bottom: -2px;
				// padding: 10px 15px;
			}
		}
	}
}

@media(max-width: 767px) {
	.ant-layout-header {
		padding: 0 20px;
	}

	.navbar {
		.navbar-toggler {
			display: inline-block;
		}
	}
}