@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import '~antd/lib/style/themes/dark.less';

//@body-background: #303030;
@component-background: #080808;
@popover-background: #080808;
//@border-color-base: #6f6c6c;
//@border-color-split: #424242;
//@table-header-sort-active-bg: #424242;
//@card-skeleton-bg: #424242;
//@skeleton-color: #424242;
//@table-header-sort-active-bg: #424242;

// Font
@font-size-base: 14px; // major text font size

h1 {
    font-size: 48px;
    font-weight: lighter;
}

h2 {
    font-size: 38px;
    font-weight: lighter;
}

h3 {
    font-size: 32px;
    font-weight: lighter;
}

h4 {
    font-size: 28px;
    font-weight: lighter;
}

h5 {
    font-size: 24px;
    font-weight: lighter;
}

h6 {
    font-size: 20px;
    font-weight: lighter;
}

p{
	font-size: 20px;
	font-weight: lighter;
}

// Rounded corners
// @border-radius-base: 15px; // major border radius
@border-radius-base: 0;

// .rouded-corners{
//     border-radius: @border-radius-base;
// }

.ant-card-actions{
    border-radius: 0 0 @border-radius-base @border-radius-base;
}

.slick-list{
    border-radius: @border-radius-base;
}

// navbar
.ant-layout-header{
    border-bottom: 0;
}

// footer
.ant-layout-footer {
    background-color: @component-background;
	padding: 60px 0 60px 0;
	text-align: center;
	
	a, h1, h2, h3, h4, h5, h6 {
		text-transform: uppercase;
	}

	.logo {
		max-height:100px;
		max-width: max(15%, 200px);
		//margin: 0 0 40px 0;
	}

	.block {
		margin: 60px 0 60px 0;
	}

	a{
		margin:0 1em 0 1em;
	}
}

@media(min-width: 767px) {
	.ant-layout-footer {
		padding-left: 20vh;
		padding-right: 20vh;
	}
}

// carousel

.ant-carousel .slick-dots li button, 
.ant-carousel .slick-dots li.slick-active button{
	background: #e2e2e2;
	background-color: #e2e2e2;
}

// nwes card
.news-card-main, .ant-card:hover{
	cursor: auto;
}
.news-card-main, .ant-card-bordered {
	border: none;
}
.news-card-main .ant-card-cover img{
	border-radius: @border-radius-base 0 0 @border-radius-base;
}

.news-card-main {
	height: 100%;
	display: flex;
	//flex-direction:column;
  	//align-items: flex-end;
}
.news-card-main .ant-card-body {
	display: flex;
  	align-items: flex-end;
}


.news-card, .ant-card:hover{
	cursor: auto;
}
.news-card, .ant-card-bordered {
	border: none;
}

.news-card {
	height: 100%;
	display: flex;
	flex-direction:column;
  	//align-items: flex-end;
}
.news-card .ant-card-body {
	height: 100%;
	display: flex;
  	align-items: flex-end;
}

.clear{
	background: none !important;
}

.ant-card-actions{
	background: none;
}

// ribbon
.ant-ribbon {
	border-radius: 4px;
}